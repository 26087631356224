import IconButton from '@mui/material/IconButton'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, Grid, SelectChangeEvent, SxProps, Theme } from '@mui/material'
import AppContext, { AppContextType } from '../../../AppContext'
import DataTable from '../../../components/DataTable/DataTable'
import { CompanyInfo, CompanyMinimalInfo, partnerTypes } from '../../../models/Company/Company'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import InputSelect from '../../../components/Form/Select/Select'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { featureAccessCheck, iconAccessCheck } from '../../../utils/AccessPermissionsCheck'
import Icons from '../../../assets/icons/svgIcons'
import EditDeletePopover from '../../../components/PopOver/EditDeletePopover'
import DeleteConfirmation, { DeleteItemText } from '../../../components/DeleteConfirmation/DeleteConfirmation'
import { ApplicationColors } from '../../../utils/applicationColors'
import { PartnerMinimalResponse } from '../../../models/Partner/Partner'
import {
  deletePartner,
  getAllDestinationPartners,
  getAllPartners,
  getAllTemporaryStoragePartners,
  getAllTransporterPartners,
} from '../../../services/Partner/PartnerService'
import ViewInfoModal from '../../../components/ViewInfoModal/ViewInfoModal'
import { ItemType } from '../../../utils/sort'

const PartnersList: React.FC = () => {
  const { setTitle, setCustomHeaderContent, showAlert, setIsShowLoading, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const [partners, setPartners] = React.useState<PartnerMinimalResponse[]>([])
  const [partnersForDataTable, setPartnersForDataTable] = React.useState<PartnerMinimalResponse[]>([])
  const [partnersOptions, setPartnersOptions] = React.useState<{ label: string; value: string }[]>([])
  const [itemToChange, setItemToChange] = React.useState<PartnerMinimalResponse>()
  const [selectedPartner, setSelectedPartner] = React.useState<string>()
  const [currentDeleteItem, setCurrentDeleteItem] = React.useState<PartnerMinimalResponse | null>(null)
  const [selectedCompanyType, setSelectedCompanyType] = React.useState<string>('all')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = React.useState<boolean>(false)
  const [deleteItemTexts, setDeleteItemTexts] = React.useState<DeleteItemText>({ title: '', item: '', name: '' })
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [anchor, setAnchor] = React.useState<any>(null)
  const [partnerInfoModalOpen, setPartnerInfoModalOpen] = React.useState<boolean>(false)
  const [modalSelectedPartner, setModalSelectedPartner] = React.useState<CompanyMinimalInfo>({} as CompanyMinimalInfo)
  const headerGridSx: SxProps<Theme> = {
    paddingRight: '8px',
    paddingLeft: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItens: 'center',
  }
  const navigate = useNavigate()
  const partnersMap = [{ key: 'name', value: 'Nome' }]

  const handlePartnersOptions = (comp: PartnerMinimalResponse[]): void => {
    setPartnersOptions(
      comp.map((c) => {
        return { label: c.name, value: c.identifier as string }
      })
    )
  }

  const setCompaniesSortedByName = (data: PartnerMinimalResponse[]): void => {
    setPartnersForDataTable(data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())))
  }

  const setAllPartnersInfoList = (): void => {
    getAllPartners()
      .then((partnersList) => {
        setPartners(partnersList)
        setPartnersForDataTable(partnersList)
        const partnersOptionsList = partnersList.map((p) => ({ label: p.name, value: p.identifier }))
        setPartnersOptions([{ label: 'Todos', value: 'Todos' }, ...partnersOptionsList])
      })
      .catch(() => showAlert('error', 'Erro ao carregar Parceiros.'))
      .finally(() => setLoading(false))
  }

  const setPartnersListEmpty = (): void => {
    setPartnersForDataTable([] as PartnerMinimalResponse[])
    setPartnersOptions([] as { label: string; value: string }[])
  }

  const setCompaniesByType = (selectedType: string): void => {
    setIsShowLoading(true)
    setLoading(true)
    switch (selectedType) {
      case 'TRANSPORTADOR':
        getAllTransporterPartners()
          .then((transporters) => {
            if (transporters && transporters.length > 0) {
              handlePartnersOptions(transporters)
              setCompaniesSortedByName(transporters)
            } else {
              setPartnersListEmpty()
              showAlert('error', 'Não existem Transportadores cadastrados.')
            }
          })
          .catch(() => {
            showAlert('error', 'Erro ao carregar Transportadores.')
            setPartnersListEmpty()
          })
          .finally(() => {
            setIsShowLoading(false)
            setLoading(false)
          })
        break
      case 'DESTINADOR':
        getAllDestinationPartners()
          .then((destinators) => {
            if (destinators && destinators.length > 0) {
              handlePartnersOptions(destinators)
              setCompaniesSortedByName(destinators)
            } else {
              setPartnersListEmpty()
              showAlert('error', 'Não existem Destinadores cadastrados.')
            }
          })
          .catch(() => {
            showAlert('error', 'Erro ao carregar Destinadores.')
            setPartnersListEmpty()
          })
          .finally(() => {
            setIsShowLoading(false)
            setLoading(false)
          })
        break
      case 'ARMAZENADOR_TEMPORARIO':
        getAllTemporaryStoragePartners()
          .then((temporaryStorage) => {
            if (temporaryStorage && temporaryStorage.length > 0) {
              handlePartnersOptions(temporaryStorage)
              setCompaniesSortedByName(temporaryStorage)
            } else {
              setPartnersListEmpty()
              showAlert('error', 'Não existem Armazenadores Temporários cadastrados.')
            }
          })
          .catch(() => {
            showAlert('error', 'Erro ao carregar Armazenadores Temporários.')
            setPartnersListEmpty()
          })
          .finally(() => {
            setIsShowLoading(false)
            setLoading(false)
          })
        break
      default:
        setAllPartnersInfoList()
        setIsShowLoading(false)
        setLoading(false)
    }
  }

  const onChangeType = (e: SelectChangeEvent<any>): void => {
    const selectedType = e.target.value
    setSelectedPartner(undefined)
    setSelectedCompanyType(selectedType)
    setCompaniesByType(selectedType)
  }
  const onChangeCompany = (event: React.SyntheticEvent<Element, Event>, value: Select2Options | null): void => {
    setSelectedPartner(value?.value as string)
    if (value && value.value === 'Todos') {
      setPartnersForDataTable(partners)
    } else if (!value) {
      setCompaniesByType(selectedCompanyType)
    } else if (value) {
      const filteredPartnerList: PartnerMinimalResponse[] = partners.filter((p) => p.identifier === value.value)
      setPartnersForDataTable(filteredPartnerList)
    }
  }

  const handleClickNew = React.useCallback(() => {
    navigate('/main/partners/form')
  }, [navigate])

  const handleEdit = (item: PartnerMinimalResponse): void => {
    navigate(`/main/partners/form/${item.identifier}`)
  }

  const openDeleteConfirmation = (item: PartnerMinimalResponse): void => {
    setIsShowDeleteConfirmation(true)
    setDeleteItemTexts({
      title: 'Excluir parceiro',
      item: 'o parceiro',
      name: item.name,
    })
    setCurrentDeleteItem(item)
  }

  const handleDelete = (item: PartnerMinimalResponse): void => {
    setIsShowLoading(true)
    deletePartner(item.identifier)
      .then(() => {
        setPartners(partners.filter((p) => p.identifier !== item.identifier))
        setPartnersOptions(partnersOptions.filter((p) => p.value !== item.identifier))
        showAlert('success', 'Parceiro excluído com sucesso.')
        setIsShowLoading(false)
      })
      .catch(() => {
        showAlert('error', 'Erro ao excluir parceiro.')
        setIsShowLoading(false)
      })
  }

  const handleDeleteConfirmationClose = (): void => {
    setIsShowDeleteConfirmation(false)
  }

  const handleConfirmDelete = (): void => {
    if (currentDeleteItem) {
      handleDelete(currentDeleteItem)
    }
    setCurrentDeleteItem(null)
    handleDeleteConfirmationClose()
    setIsOpen(false)
  }

  const customHeaderContent = React.useMemo(
    () =>
      featureAccessCheck('company-write') ? (
        <IconButton aria-label="Nova empresa" data-cy="go-to-partnerform-button" onClick={handleClickNew} size="large">
          <Icons.Add fill="white" />
        </IconButton>
      ) : (
        <div />
      ),
    [handleClickNew]
  )

  const handleInit = React.useCallback((): void => {
    setLoading(true)
    setTitle('Listar Parceiros')
    setCustomHeaderContent(customHeaderContent)

    setAllPartnersInfoList()
  }, [customHeaderContent, setCustomHeaderContent, setTitle, showAlert])

  React.useEffect(() => {
    handleInit()
  }, [handleInit])

  const handleClose = (): void => {
    setIsOpen(false)
  }

  const handleMoreOptions = (
    item: PartnerMinimalResponse,
    index?: number,
    anchorEl?: EventTarget & HTMLButtonElement
  ): void => {
    setIsOpen(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  const handleOpenPartnerInfoModal = (client: ItemType<any>): void => {
    const clientInfo = {
      name: client.name as string,
      cnpj: client.cnpj as string,
      identifier: client.identifier as string,
    }
    setModalSelectedPartner(clientInfo)
    setPartnerInfoModalOpen(true)
  }

  const handleClosePartnerInfoModal = (): void => {
    setPartnerInfoModalOpen(false)
  }

  React.useEffect(() => {
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Listar parceiros', path: '/main/partners/list' }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ViewInfoModal
        open={partnerInfoModalOpen}
        handleModalClose={handleClosePartnerInfoModal}
        partner={modalSelectedPartner}
      />
      <DeleteConfirmation
        handleConfirmDelete={handleConfirmDelete}
        isShowDeleteConfirmation={isShowDeleteConfirmation}
        handleClose={handleDeleteConfirmationClose}
        deleteItemTexts={deleteItemTexts}
      />
      <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Grid item xs={6} sx={headerGridSx}>
          <Card className="card-filters" variant="outlined">
            <InputSelect
              id="companyType"
              label="Tipo Empresa"
              options={[{ value: 'all', label: 'Todos' }, ...partnerTypes]}
              onChange={onChangeType}
              sx={{ borderRadius: '4px' }}
              value={selectedCompanyType}
            />
          </Card>
        </Grid>
        <Grid item xs={6} sx={headerGridSx}>
          <Card className="card-filters" variant="outlined">
            <InputSelect2
              id="companyOption"
              label="Parceiro"
              options={partnersOptions}
              onChange={onChangeCompany}
              clearable
              sx={{ borderRadius: '4px' }}
              value={selectedPartner}
            />
          </Card>
        </Grid>
      </Grid>
      <div className="filter--open">
        <DataTable
          data={partnersForDataTable}
          dataMap={partnersMap}
          loading={loading}
          actions={
            iconAccessCheck('company-edit') || iconAccessCheck('company-delete')
              ? [
                  {
                    label: 'Opções',
                    icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />,
                    fn: handleMoreOptions,
                  },
                ]
              : []
          }
          handleOpenInfoModal={handleOpenPartnerInfoModal}
        />
        <EditDeletePopover
          editFunction={iconAccessCheck('company-edit') ? handleEdit : undefined}
          deleteFunction={iconAccessCheck('company-delete') ? openDeleteConfirmation : undefined}
          open={isOpen}
          item={itemToChange}
          handleClose={handleClose}
          anchor={anchor}
        />
      </div>
    </>
  )
}

export default PartnersList
