import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import AppContext, { AppContextType } from '../../../AppContext'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import InputSelect2Multiple from '../../../components/Form/Select/Select2Multiple'
import { getPartnersByCustomer, updatePartnersInCustomer } from '../../../services/Contract/ContractService'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { getAllCustomers, getAllPartners } from '../../../services/Companies/CompaniesService'
import { CustomerPartners } from '../../../models/Company/Operation'
import { CompanyMinimalInfo } from '../../../models/Company/Company'
import getInitialSelectedCompany, { getCompanyByIdentifier } from '../../../utils/getInitialSelectedCompany'

const validationSchema = yup.object({
  identifierCustomer: yup.string().required('campo obrigatório'),
})

const ContractForm: React.FC = () => {
  const {
    setTitle,
    setCustomHeaderContent,
    setIsShowLoading,
    showAlert,
    setItemsBreadCrumb,
    defaultCompany,
    setDefaultCompany,
  } = React.useContext(AppContext as React.Context<AppContextType>)
  const navigate = useNavigate()
  const [customers, setCustomers] = React.useState<CompanyMinimalInfo[]>([])
  const [customersMap, setCustomersMap] = React.useState<{ value: string; label: string }[]>([])
  const [partnersMap, setPartnersMap] = React.useState<{ value: string; label: string }[]>([])
  const [selectedValues, setSelectedValues] = React.useState<string[]>([])
  const resolver = useYupValidationResolver(validationSchema)
  const {
    formState: { isDirty },
    control,
    formState,
    handleSubmit,
    setValue,
  } = useForm<CustomerPartners>({
    resolver,
    defaultValues: { identifierCustomer: '', partners: selectedValues },
  })

  const onSubmit: SubmitHandler<CustomerPartners> = (data: CustomerPartners): void => {
    if (isDirty) {
      setIsShowLoading(true)
      updatePartnersInCustomer(data)
        .then(() => {
          showAlert('success', 'Contrato salvo com sucesso.')
          setIsShowLoading(false)
          navigate('/main/contract/list')
        })
        .catch(() => {
          showAlert('error', 'Erro ao salvar contrato. Tente novamente mais tarde.')
          setIsShowLoading(false)
        })
    } else {
      showAlert('info', 'Nada foi modificado')
    }
  }

  const handlePartnersByCustomerIdentifier = (customerIdentifier: string): void => {
    getPartnersByCustomer(customerIdentifier)
      .then((partnersInfo) => {
        const partnersByCustomer = partnersInfo.map((partner) => partner.identifier)
        setSelectedValues(partnersByCustomer)
        setValue('partners', partnersByCustomer)
      })
      .catch((error) => {
        setSelectedValues([])
        showAlert('info', 'Cliente não possui contratos cadastrados.')
      })
      .finally(() => setIsShowLoading(false))
  }

  const setDefaultCompanyInfo = (customerIdentifier: string): void => {
    const customerInfo = getCompanyByIdentifier(customers, customerIdentifier)
    setDefaultCompany(customerInfo)
  }

  const handleCompanyChange = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    const customerIdentifier = value?.value as string
    handlePartnersByCustomerIdentifier(customerIdentifier)
    setDefaultCompanyInfo(customerIdentifier)
    setValue('identifierCustomer', customerIdentifier, { shouldDirty: true })
  }

  const handleIdPartnersChange = (event: React.SyntheticEvent, value: Select2Options[] | null): void => {
    setSelectedValues([...(value?.map((val) => val.value) as string[])])
    setValue('partners', value ? value.map((v) => v.value as string) : [], { shouldDirty: true })
  }

  const handlePartnersData = (): void => {
    try {
      getAllPartners().then((partnersInfo) => {
        const partnersOptions = partnersInfo.map((partner) => {
          return {
            value: partner.identifier,
            label: partner.name,
          }
        })
        setPartnersMap(partnersOptions)
      })
    } catch {
      throw new Error('Erro ao carregar Empresas Parceiras.')
    }
  }

  const handleCompaniesData = (): void => {
    try {
      getAllCustomers().then((customersInfo) => {
        const initialSelectedCustomer = getInitialSelectedCompany(customersInfo, defaultCompany)
        const customersOptions = customersInfo.map((customer) => {
          return {
            value: customer.identifier,
            label: customer.name,
          }
        })
        setCustomers(customersInfo)
        setCustomersMap(customersOptions)
        setValue('identifierCustomer', initialSelectedCustomer?.identifier, { shouldDirty: true })
        handlePartnersByCustomerIdentifier(initialSelectedCustomer?.identifier)
      })
    } catch {
      throw new Error('Erro ao carregar Clientes.')
    }
  }

  const handleInit = (): void => {
    setIsShowLoading(true)
    try {
      handleCompaniesData()
      handlePartnersData()
    } catch (error) {
      showAlert('error', (error as Error).message)
    } finally {
      setIsShowLoading(false)
    }
  }

  React.useEffect(() => {
    handleInit()
    setTitle('Novo Contrato')
    setCustomHeaderContent(<div />)
  }, [setTitle, setCustomHeaderContent])

  React.useEffect(() => {
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar contratos', path: '/main/contract/list' },
      { label: 'Novo contrato', path: '/main/contract/form' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card className="card card--form card-forms-styles" variant="outlined">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="identifierCustomer"
              control={control}
              render={({ field }) => (
                <InputSelect2
                  id="identifierCustomer"
                  label="Cliente"
                  errorText={formState.errors?.identifierCustomer?.message}
                  options={customersMap}
                  value={field.value}
                  onChange={handleCompanyChange}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="partners"
              control={control}
              defaultValue={selectedValues}
              render={({ field }) => (
                <InputSelect2Multiple
                  id="partners"
                  label="Empresas Parceiras"
                  errorText={(formState.errors?.partners as unknown as FieldError)?.message}
                  options={partnersMap}
                  {...field}
                  value={selectedValues}
                  onChange={handleIdPartnersChange}
                />
              )}
            />
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '24px',
              }}
            >
              <Button variant="contained" color="primary" type="submit">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Card>
  )
}

export default ContractForm
