import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import AppContext, { AppContextType } from '../../../AppContext'
import DataTable from '../../../components/DataTable/DataTable'
import InputText from '../../../components/Form/Input/Input'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import { MtrModelFormProps, WasteModel } from '../../../models/MtrModel/MtrModel'
import { getVehiclesByPartnerIdentifier } from '../../../services/Vehicle/VehicleService'
import WasteFormDialog from '../../Gathering/WasteFormDialog/WasteFormDialog'
import EditDeletePopover from '../../../components/PopOver/EditDeletePopover'
import Icons from '../../../assets/icons/svgIcons'
import { ApplicationColors } from '../../../utils/applicationColors'
import { CompanyMinimalInfo } from '../../../models/Company/Company'
import { getPartnersByCustomerIdentifier } from '../../../services/Client/ClientPartnerService'
import { getAllCustomers } from '../../../services/Companies/CompaniesService'
import getInitialSelectedCompany, { getCompanyByIdentifier } from '../../../utils/getInitialSelectedCompany'

const MtrModelMainForm: React.FC<MtrModelFormProps> = ({
  contractsMap,
  setContractsMap,
  vehiclesMap,
  setVehiclesMap,
  companiesMap,
  setCompaniesMap,
  handleInit,
  onSubmit,
  useFormObject,
}) => {
  const { handleSubmit, setValue, getValues, control, formState, watch } = useFormObject
  const { id } = useParams<{ id: string | undefined }>()
  const [open, setOpen] = React.useState<boolean>(false)
  const [waste, setWaste] = React.useState<WasteModel | null>(null)
  const [isPoppoverOpened, setIsPopoverOpened] = React.useState<boolean>(false)
  const [anchor, setAnchor] = React.useState<any>(null)
  const [itemToChange, setItemToChange] = React.useState<WasteModel>()
  const [customers, setCustomers] = React.useState<CompanyMinimalInfo[]>([])
  const { setIsShowLoading, showAlert, defaultCompany, setDefaultCompany } = React.useContext(
    AppContext as React.Context<AppContextType>
  )

  const handleClickOpen = (): void => {
    setWaste(null)
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleEditWaste = (item: WasteModel): void => {
    setOpen(true)
    setWaste(item)
  }

  const handleDeleteWaste = (item: WasteModel): void => {
    const selectedWastes = getValues('wasteModel')
    if (selectedWastes.length === 1) {
      setValue('wasteModel', [])
    } else {
      const wasteWithoutThis = selectedWastes.filter((w) => item.wasteCode !== w.wasteCode)
      setValue('wasteModel', wasteWithoutThis)
    }
  }

  const handleSaveWaste = (wasteData: WasteModel): void => {
    const selectedWastes = getValues('wasteModel')
    const wasteObj = { ...wasteData }
    if (waste) {
      const newValue = selectedWastes.map((item) => {
        if (waste.wasteCode === item.wasteCode) {
          return wasteObj
        }
        return item
      })
      setValue('wasteModel', newValue)
    } else if (selectedWastes) {
      const newWasteData = [...selectedWastes, wasteObj]
      setValue('wasteModel', newWasteData)
    } else {
      setValue('wasteModel', [wasteObj])
    }
    setWaste(null)
  }

  const setPartnersAndVehicles = (generatorIdentifier: string): void => {
    setIsShowLoading(true)
    getPartnersByCustomerIdentifier(generatorIdentifier)
      .then((partners) =>
        setContractsMap(
          partners.map((p) => {
            return {
              value: p.identifier,
              label: p.name,
            }
          })
        )
      )
      .catch(() => {
        showAlert('error', 'Não foi possível encontrar contratos para esta empresa')
      })
      .finally(() => {
        setIsShowLoading(false)
      })
  }

  const setDefaultCompanyInfo = (customerIdentifier: string): void => {
    if (!id) {
      const customer = getCompanyByIdentifier(customers, customerIdentifier)
      if (customer) setDefaultCompany(customer)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleGeneratorChange = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    setValue('generatorIdentifier', value?.value as string)
    setPartnersAndVehicles(value?.value as string)
    setDefaultCompanyInfo(value?.value as string)
  }

  const handleTransporterChange = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    setValue('transporterIdentifier', value?.value as string)
  }

  const handleReceiverChange = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    setValue('receiverIdentifier', value?.value as string)
  }

  const handleMoreOptions = (item: WasteModel, index?: number, anchorEl?: EventTarget & HTMLButtonElement): void => {
    setIsPopoverOpened(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  const handleClosePopover = (): void => {
    setIsPopoverOpened(false)
  }

  const handleGeneratorInitialData = (customersData: CompanyMinimalInfo[]): void => {
    if (!id) {
      const initialSelectedCustomer = getInitialSelectedCompany(customersData, defaultCompany)
      setValue('generatorIdentifier', initialSelectedCustomer.identifier)
      setPartnersAndVehicles(initialSelectedCustomer.identifier)
    }
  }

  const handleCompaniesData = (companiesData: CompanyMinimalInfo[]): void => {
    const allBranches: { value: string; label: string }[] = companiesData.map((customer) => {
      return { label: customer.name, value: customer.identifier }
    })
    setCompaniesMap(allBranches)
    handleGeneratorInitialData(companiesData)
  }

  const loadData = React.useCallback(async (): Promise<void> => {
    const customersList = await getAllCustomers()
    setCustomers(customersList)
    handleCompaniesData(customersList)
  }, [])

  React.useEffect(() => {
    setIsShowLoading(true)

    loadData()
      .then(handleInit)
      .finally(() => setIsShowLoading(false))
  }, [setIsShowLoading, handleInit])

  return (
    <Card className="card card--form card-forms-styles" variant="outlined">
      <WasteFormDialog waste={waste} open={open} handleClose={handleClose} handleSave={handleSaveWaste} isMtrModel />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
        {companiesMap.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} key="name">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    id="name"
                    type="text"
                    label="Nome do Modelo"
                    errorText={formState.errors?.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            {companiesMap.length > 0 && (
              <Grid item xs={12} sm={12} key="generatorIdentifier">
                <Controller
                  name="generatorIdentifier"
                  control={control}
                  defaultValue={watch('generatorIdentifier')}
                  render={({ field }) => (
                    <InputSelect2
                      id="generatorIdentifier"
                      label="Geradora"
                      errorText={formState.errors?.generatorIdentifier?.message}
                      options={companiesMap}
                      onChange={handleGeneratorChange}
                      value={field.value}
                    />
                  )}
                />
              </Grid>
            )}
            {contractsMap?.length > 0 && (
              <>
                <Grid item xs={12} sm={6} key="transporterIdentifier">
                  <Controller
                    name="transporterIdentifier"
                    control={control}
                    defaultValue={contractsMap[0]?.value as string}
                    render={({ field }) => (
                      <InputSelect2
                        id="transporterIdentifier"
                        label="Transportadora"
                        errorText={formState.errors?.transporterIdentifier?.message}
                        options={contractsMap}
                        onChange={handleTransporterChange}
                        value={field.value}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} key="receiverIdentifier">
                  <Controller
                    name="receiverIdentifier"
                    control={control}
                    defaultValue={contractsMap[0]?.value as string}
                    render={({ field }) => (
                      <InputSelect2
                        id="receiverIdentifier"
                        label="Destinadora"
                        errorText={formState.errors?.receiverIdentifier?.message}
                        options={contractsMap}
                        onChange={handleReceiverChange}
                        value={field.value}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <Controller
                name="driverName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    id="driverName"
                    label="Motorista"
                    errorText={formState.errors?.driverName?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="vehiclePlate"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    id="vehiclePlate"
                    label="Veículo"
                    errorText={formState.errors?.vehiclePlate?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} key="mtrObservation">
              <Controller
                name="mtrObservation"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    id="mtrObservation"
                    type="text"
                    label="Observação"
                    errorText={formState.errors?.mtrObservation?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginTop: '24px',
          }}
        >
          <Button className="anti-card" variant="outlined" color="primary" onClick={handleClickOpen}>
            Adicionar Resíduo
          </Button>
        </Grid>
        <Grid style={{ margin: '-80px -16px 0px -16px', marginTop: '40px' }}>
          <DataTable
            data={watch('wasteModel') || []}
            dataMap={[{ key: 'name', value: 'Nome' }]}
            loading={false}
            compact
            actions={[
              {
                label: 'Ações',
                icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />,
                fn: handleMoreOptions,
              },
            ]}
            config={{
              showPagination: false,
            }}
          />
          <EditDeletePopover
            editFunction={handleEditWaste}
            deleteFunction={handleDeleteWaste}
            open={isPoppoverOpened}
            item={itemToChange}
            handleClose={handleClosePopover}
            anchor={anchor}
          />
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '24px',
          }}
        >
          <Button variant="contained" color="primary" type="submit">
            Salvar
          </Button>
        </Grid>
      </form>
    </Card>
  )
}

export default MtrModelMainForm
